<template>
  <div class="form-group" :class="classes" :style="styles">
    <img src="@/assets/media/remove.svg" alt="" style="display: none;" />
    <b v-if="label">{{ label }} <slot name="label-right"></slot></b>
    <input
      ref="FileInput"
      type="file"
      :accept="allowExtensions.join(',')"
      :multiple="max > 1"
      id="addFileForNeeds"
      @change="getFile"
      class="vInput"
    />
    <div class="vInputLabelParent">
      <span v-tooltip="maxFiles ? `Se pot atașa maxim: ${max} fișiere.` : ''">
        <label
          class="vInputLabel"
          for="addFileForNeeds"
          :style="maxFiles ? 'pointer-events: none;' : ''"
          ><span>Atașează</span>
          <img src="@/assets/media/attach_file.svg" alt=""
        /></label>
      </span>
      <img src="@/assets/media/remove.svg" style="display: none;" alt="" />
      <span
        v-for="(file, index) in files"
        :key="file.key"
        class="vInputLabelFile"
      >
        <v-popover
          ref="fileName"
          popoverInnerClass="popover-file-name-inner"
          popoverBaseClass="tooltip popover popover-file-name-container"
          popoverArrowClass=""
        >
          <span>{{ file.file.name }}</span>
          <div slot="popover" class="popover-file-name-change">
            <div class="current-name">{{ file.file.name }}</div>
            <div class="editable-input-container">
              <div
                class="editable-input"
                style="position: relative; width: fit-content;"
              >
                <input
                  :ref="file.file.name + index"
                  :value="file.file.name.split('.').slice(0, -1)"
                  type="text"
                  class=""
                  maxlength="150"
                />
                <i class="fa-solid fa-circle-xmark editable-clear-x" @click="clearFileNameInput(file.file.name + index)"></i>
              </div>
              <button
                class="editable-submit"
                @click="changeName(file.file.name + index, file)"
              >
                <i class="fas fa-check"></i>
              </button>
            </div>
          </div>
        </v-popover>

        <img
          @click="delFile(file.index)"
          src="@/assets/media/remove.svg"
          alt=""
        />
      </span>
    </div>
    <small class="form-text text-muted"
      >Extensii permise
      <i
        v-tooltip.bottom="allowExtensions.join(', ')"
        class="fas fa-info-circle"
      ></i
    ></small>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "Documente",
    },
    signDeleted: {
      type: Boolean,
      default: false,
    },
    styles: {
      type: [Object, String],
      default: "",
    },
    classes: {
      type: [Object, String, Array],
      default: "",
    },
    max: {
      type: Number,
      default: 20,
    },
    allowExtensions: {
      type: Array,
      default: () => [
        ".doc",
        ".docx",
        "xls",
        "xlsx",
        ".pptx",
        "txt",
        ".ppt",
        ".pdf",
        ".bmp",
        ".png",
        ".jpg",
        ".jpeg",
      ],
    },
  },
  computed: {
    files() {
      return Array.isArray(this.value)
        ? this.value
            .map((e, i) => ({ ...e, index: i }))
            .filter((e) => !e.deleted)
        : [];
    },
    maxFiles() {
      return this.files.length >= this.max;
    },
  },
  methods: {
    clearFileNameInput(id) {
      this.$refs[id][0].value = null;
    },
    changeName(id, file) {
      const newName = this.$refs[id][0].value;
      if (!newName || newName == "" || newName == " ") {
        return;
      }
      const fileValueIndex = this.value.findIndex((el) => el.key == file.key);
      const newFile = new File(
        [file.file],
        `${newName}.${file.file.name.split(".").pop()}`,
        { type: file.file.type }
      );
      const key = `${newFile.size}|${newFile.lastModified}|${newFile.name}`;

      this.value[fileValueIndex].key = key;
      this.value[fileValueIndex].file = newFile;
    },
    up(x) {
      this.$emit("input", x);
    },
    clearInput() {
      try {
        this.$refs["FileInput"]["value"] = "";
      } catch (err) {
        console.error(err);
      }
    },
    getFile(event) {
      if (!event?.target?.files) {
        return;
      }

      const prepareFiles = [];
      let haveBeenAdded = 0;

      const x = Array.from(event.target.files);
      for (const file of x) {
        const key = `${file.size}|${file.lastModified}|${file.name}`;
        if (this.files.length + prepareFiles.length >= this.max) {
          this.$toastr.w({
            msg: `Numărul maxim de fișiere este: ${this.max}.`,
            preventDuplicates: true,
          });
          break;
        }
        if (file?.size > 10485760) {
          this.$toastr.w({
            msg: "Mărimea maximă pentru un fișier nu poate depăși 10 MB.",
            preventDuplicates: true,
          });
          continue;
        }
        if (!this.files.find((e) => e.key === key)) {
          prepareFiles.push({
            file,
            key,
            deleted: false,
          });
        } else {
          haveBeenAdded++;
        }
      }

      this.clearInput();

      if (prepareFiles.length) {
        // this.up(this.files.concat(prepareFiles))
        this.value.push(...prepareFiles);
        if (haveBeenAdded) {
          this.$toastr.w("Unele fișiere selectate de dvs. deja există!");
          return;
        }
      }

      if (haveBeenAdded) {
        this.$toastr.w(
          (haveBeenAdded === 1 ? "Fișierul selectat" : "Fișierele selectate") +
            " de dvs. deja există!"
        );
      }
    },
    delFile(index) {
      if (this.signDeleted) this.$set(this.value[index], "deleted", true);
      else this.value.splice(index, 1);
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
@mixin css-arrow(
  $height: 10px,
  $border: 2px,
  $color: #efefef,
  $border-color: #999,
  $direction: bottom,
  $outside: true
) {
  $border-shift: $border;

  @if $border > 0px {
    $border: round($border * 1.41421356);
  }

  position: relative;

  &:after,
  &:before {
    border: solid transparent;
    content: " ";
    display: block;
    height: 0;
    position: absolute;
    pointer-events: none;
    width: 0;

    @if $direction == top {
      @if $outside {
        bottom: 100%;
      } @else {
        margin-top: -$border-shift;
        top: 0;
      }
    }
    @if $direction == right {
      @if $outside {
        left: 100%;
      } @else {
        margin-right: -$border-shift;
        right: 0;
      }
    }
    @if $direction == bottom {
      @if $outside {
        top: 100%;
      } @else {
        bottom: 0;
        margin-bottom: -$border-shift;
      }
    }
    @if $direction == left {
      @if $outside {
        right: 100%;
      } @else {
        left: 0;
        margin-left: -$border-shift;
      }
    }
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    @if $direction == top {
      @if $outside {
        border-bottom-color: $color;
      } @else {
        border-top-color: $color;
      }
    }
    @if $direction == right {
      @if $outside {
        border-left-color: $color;
      } @else {
        border-right-color: $color;
      }
    }
    @if $direction == bottom {
      @if $outside {
        border-top-color: $color;
      } @else {
        border-bottom-color: $color;
      }
    }
    @if $direction == left {
      @if $outside {
        border-right-color: $color;
      } @else {
        border-left-color: $color;
      }
    }

    @if $direction == top or $direction == bottom {
      left: 50%;
      margin-left: -$height;
    }

    @if $direction == left or $direction == right {
      top: 50%;
      margin-top: -$height;
    }

    border-width: $height;
  }

  &:before {
    border-color: rgba(255, 255, 255, 0);
    @if $direction == top {
      @if $outside {
        border-bottom-color: $border-color;
      } @else {
        border-top-color: $border-color;
      }
    }
    @if $direction == right {
      @if $outside {
        border-left-color: $border-color;
      } @else {
        border-right-color: $border-color;
      }
    }
    @if $direction == bottom {
      @if $outside {
        border-top-color: $border-color;
      } @else {
        border-bottom-color: $border-color;
      }
    }
    @if $direction == left {
      @if $outside {
        border-right-color: $border-color;
      } @else {
        border-left-color: $border-color;
      }
    }

    @if $direction == top or $direction == bottom {
      left: 50%;
      margin-left: -($height + $border);
    }

    @if $direction == left or $direction == right {
      top: 50%;
      margin-top: -($height + $border);
    }

    border-width: $height + $border;
  }
}

$background: #f7f7f7;
$border-color: #ccc;
$border-size: 1px;

.popover-file-name-container {
  pointer-events: all;
  max-width: 100% !important;
  padding: 0px !important;
  @include css-arrow(10px, $border-size, $background, $border-color, top);
  top: 50px;
}
.popover-file-name-inner {
  background-color: transparent;
  width: 100%;
}
.popover-file-name-change {
  color: black;

  .current-name {
    font-weight: 500;
    padding: 8px 14px;
    margin: 0;
    font-size: 14px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-radius: 5px 5px 0 0;
  }
  .editable-input-container {
    padding: 8px 14px;
    display: flex;
    align-items: center;
    .editable-input {
      margin-bottom: 0 !important;
    }
  }

  input {
    padding-right: 24px !important;
    padding: 5px 10px;
    font-size: 12px;
  }

  .editable-submit {
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
    padding: 6px 15px;
    margin-left: 5px;
    border: none;
    border-radius: 2px;
  }
  .editable-clear-x {
    font-size: 16px;
    opacity: 0.5;
    position: absolute;
    width: max-content;
    display: flex;
    align-items: center;
    top: 0;
    bottom: 0;
    right: 3px;
    cursor: pointer;
  }
}
</style>
